<template>
  <loading v-if="!site" centered />

  <div v-else class="columns is-multiline is-marginless has-padding-50">
    <div class="column is-12">
      <div class="box is-paddingless">
        <screenshot
          :filename="site.screenshot || ''"
          :title="site.fqdn || 'loading'"
          refreshable
          classes="has-background-white"
          @refresh="recaptureScreenshot"
        />
      </div>
    </div>

    <div class="column is-12">
      <collapse title="Staff controls">
        <site-controls :site-id="siteId" />
        <user-controls :user-id="site.authorId" />
      </collapse>
    </div>

    <div v-if="site.hostingData" class="column is-12">
      <site-external-logins
        :site="site"
        :tag="'collapse'"
        :title="'External logins'"
      />
    </div>

    <div class="column is-12">
      <collapse title="Site details">
        <site-details :site="site" />
      </collapse>
    </div>

    <div class="column is-12">
      <user-details :user-id="site.authorId" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminSiteAside",
  components: {
    "site-details": () => import("@shared/sites/_siteDetails"),
    "site-external-logins": () => import("@shared/sites/_siteExternalLogins"),
    "site-controls": () => import("@shared/admin/_siteControls"),
    "user-controls": () => import("@shared/admin/_userControls"),
    "user-details": () => import("@shared/sites/_userDetails")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    siteUserRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    }
  },
  methods: {
    recaptureScreenshot() {
      this.$store
        .dispatch("sites/recaptureScreenshot", { siteId: this.siteId })
        .then(result => {
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error submitting recapture request",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
